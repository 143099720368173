// Global style imports
import 'shared/styles/global.css'
import 'shared/styles/graphik.css'
import 'shared/styles/lyon.css'

import App, { Container } from 'next/app'
import { ErrorBoundary } from 'shared/components/bugsnag'
import Head from 'shared/components/seo/head'
import { LayoutDefault } from '~/components/layouts'
import Router from 'next/router'
import withGA from 'shared/utils/analytics'

const PecanApp = ({ Component, pageProps }) => {
  return (
    <ErrorBoundary>
      <Container>
        <LayoutDefault>
          <Head>
            <link href="/static/favicon.ico" rel="icon" type="image/x-icon" />
            <meta
              name="google-site-verification"
              content="m7Wv_T7NfII_ynwaecyKZuJZodFO2WnhCW4uDN1zQFs"
            />
            {process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'master' && (
              <script
                src="//rum-static.pingdom.net/pa-5ca7ccd59a3f830016000c5d.js"
                type="text/javascript"
              />
            )}
          </Head>
          <Component {...pageProps} />
        </LayoutDefault>
      </Container>
    </ErrorBoundary>
  )
}

PecanApp.getInitialProps = async context => {
  const props = await App.getInitialProps(context)

  return {
    ...props,
  }
}

export default withGA(process.env.GOOGLE_ANALYTICS, Router)(PecanApp)
